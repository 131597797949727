import slugify from "slugify"

import { eventUrl } from "../routes"

export const eventCategoryToSlug = category =>
  slugify(category, {
    lower: true,
  })

export const createEventListUrl = (page = 1, category, tag) => {
  let url = eventUrl

  if (category && category !== `_all`) {
    url += `${eventCategoryToSlug(category)}/`
  }

  if (tag) {
    url += `tag/${eventCategoryToSlug(tag)}/`
  }

  if (page > 1) {
    url += `${page}/`
  }

  return url
}

export const createEventPostUrl = slug => slug && `${eventUrl}${slug}/`

export const createEventExcerpt = ({ raw:json } = {}, trim) => {
  if (!json) return ``

  if (trim === true) trim = 200

  const {
    content = [],
  } = JSON.parse(json);

  const [firstParagraph] = content.filter(
    ({ nodeType }) => nodeType === `paragraph`
  )

  if (!firstParagraph) return ``

  const processString = test =>
    test.reduce((acc, k) => {
      if (k.nodeType === `text`) {
        acc += k.value
      } else {
        acc += processString(k.content)
      }

      return acc
    }, ``)

  const string = processString(firstParagraph.content)

  return string.length > trim - 1 ? `${string.substr(0, trim - 1)}…` : string
}

export const converToDateString = date => {
  const month = date.getMonth()
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]
  return `${months[month]} ${`${date.getDate()}`.length === 1 ? "0" + date.getDate() : date.getDate()}`
}
